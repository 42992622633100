import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
//import './lib/foundation-explicit-pieces';


$(document).foundation();

if(localStorage.getItem('basketID') === null)
{ 
   $.post("assets/php/process.php",JSON.stringify({mode: "new_basket"}),function(data,status){localStorage.setItem('basketID',data.basketID['unum'])}, 'json');
}
var basketID = localStorage.getItem('basketID');

// Copyright Date
var copyd = new Date().getFullYear();
var origind = 2020;

//if(origind < copyd)
//{
//  $("#copydate span").append(origind + ' - ' + copyd);
//}
//else
//{
  $("#copydate span").text(copyd);
  $("#currdate span").text(copyd);
//}

if(1)//!sessionStorage.getItem("menu"))
{
  $.post("assets/php/process.php",
  JSON.stringify({
    mode: "get_menu",
    page: /[^/]*$/.exec(window.location.pathname)[0],
    basketID: basketID
  }),pagesetup
  , 'json');
}
else
{
  pagesetup(sessionStorage.getItem("menu"),'restored')
}



function pagesetup(data, status){
    $('#menu').empty();
    $.each(data.menu,function(index, element){
	if(element.Active==1)
	{$("#menu").append('<li class="is-active"><a href="' + element.Page  + '">' + element.Page_Title + '</a></li>');}
	else
	{$("#menu").append('<li><a href="' + element.Page  + '">' + element.Page_Title + '</a></li>');}
	
	if(localStorage.getItem('basketID') != data.basketID['unum'])
	{
	  localStorage.setItem('basketID',data.basketID['unum']);
	  basketID = localStorage.getItem('basketID');
        }
    });
	var elem = $('#frontimage');
	Foundation.Motion.animateIn(elem, 'fade-in');
	var elem = $('#titlename');
        Foundation.Motion.animateIn(elem, 'sil');
        var elem = $('#titleactivity');
        Foundation.Motion.animateIn(elem, 'sir');
        var elem = $('#shoptext');
        Foundation.Motion.animateIn(elem, 'siu');
        var elem = $('#actiontext');
        Foundation.Motion.animateIn(elem, 'sid');
        if(status != 'restored')
          sessionStorage.setItem("menu",data);
 }
 
var ppages = ['basket.html','order.html','payment.html?'];
if(ppages.indexOf(location.href.split('/').pop()) !== -1)
{
  var template = $('#pageul').find('li:first').clone(true);
  var discnt   = $('#pageul').find('li:nth-child(2)').clone(true);
  var postg    = $('#pageul').find('li:nth-child(3)').clone(true);
  var grand    = $('#pageul').find('li:nth-child(4)').clone(true);
  
  var rws = 0;
  template.unwrap();
  $('#page').find('li:first').remove();
  $('#page').find('li:first').remove();
  $('#page').find('li:first').remove();
  $('#page').find('li:first').remove();
  $('#pageul').remove();

  
  $.post("assets/php/process.php",JSON.stringify({
                                                   mode: "get_basket",
                                                   basketID: basketID
                                                 }),function(data,status){
                                                         $.each(data.basket,function(i, element){
								    rws += 1;
                                                                    $('#page').append(template.html().replace(/::UID::/g
                                                                    ,element.ItemID)
                                                                           .replace('::Name::',element.Name)
                                                                           .replace('::Qty::',element.Quantity)
                                                                           .replace('::Prc::',element.Price)
                                                                           .replace('::Tot::',element.Total));
                                                                    $("#plus-" + element.ItemID).click(function(){
                                                                                                                  $.post("assets/php/process.php",JSON.stringify({
                                                                                                                                                                   mode: "basket_Add",
                                                                                                                                                                   item: element.ItemID,
                                                                                                                                                                   basketID: basketID
                                                                                                                                                                 }),function(data, status){
                                                                                                                                                                                            $("#qty-" + element.ItemID).html(data.quantity[0]['Quantity']);
                                                                                                                                                                                            $("#tot-" + element.ItemID).html('&pound;' + data.quantity[0]['Total']);
                                                                                                                                                                                            $("#gtot").html('&pound;' + data.grand[0]['Grand']);
                                                                                                                                                                                          }, 'json');
                                                                                                                 });
                                                                    $("#minus-" + element.ItemID).click(function(){
                                                                                                                  $.post("assets/php/process.php",JSON.stringify({
                                                                                                                                                                   mode: "basket_Sub",
                                                                                                                                                                   item: element.ItemID,
                                                                                                                                                                   basketID: basketID
                                                                                                                                                                 }),function(data, status){
                                                                                                                                                                                            if(typeof data.quantity[0] === "undefined")
                                                                                                                                                                                            {
                                                                                                                                                                                              $("#row-" + element.ItemID).remove();
																							      $("#gtot").html('&pound;' + data.grand[0]['Grand']);
																							      rws -= 1;
																							      if(rws == 0)
																  									$('#ordercon').prop('disabled', true);
                                                                                                                                                                                            }
                                                                                                                                                                                            else
                                                                                                                                                                                            {
                                                                                                                                                                                              $("#qty-" + element.ItemID).html(data.quantity[0]['Quantity']);
                                                                                                                                                                                              $("#tot-" + element.ItemID).html('&pound;' + data.quantity[0]['Total']);
                                                                                                                                                                                              $("#gtot").html('&pound;' + data.grand[0]['Grand']);
                                                                                                                                                                                            }
                                                                                                                                                                                          }, 'json');
                                                                                                                 });
                                                                    $("#delete-" + element.ItemID).click(function(){
                                                                                                                  $.post("assets/php/process.php",JSON.stringify({
                                                                                                                                                                   mode: "basket_Del",
                                                                                                                                                                   item: element.ItemID,
                                                                                                                                                                   basketID: basketID
                                                                                                                                                                 }),function(data, status){if(typeof data.quantity[0] === "undefined")
                                                                                                                                                                                            {
                                                                                                                                                                                              $("#row-" + element.ItemID).remove();
																								rws -=1;
																								if(rws == 0)
																	  							   $('#ordercon').prop('disabled', true);

                                                                                                                                                                                              $("#gtot").html('&pound;' + data.grand[0]['Grand']);
                                                                                                                                                                                            }
                                                                                                                                                                                          }, 'json');
                                                                                                                 });
                                                                                                });
                                                                    $('#page').append(discnt.html().replace('::dct::',data.grand[0]['Discount']));
                                                                    $('#page').append(postg.html().replace('::pst::',data.grand[0]['Postage']));
                                                                    $('#page').append(grand.html().replace('::Grd::',data.grand[0]['Grand']));
                                                                    if(data.grand[0]['Discount'] == 0)
                                                                    {
                                                                      $("#dog").addClass("hide");
                                                                    }
                                                                    else
                                                                      $("#dog").removeClass("hide");
                                                                    
                                                                    if(rws == 0)
  									$('#ordercon').prop('disabled', true); 
                                                                    $('#title').val(data.customer[0]['Title']);
                                                                    $('#title').change(function(e){updateForm(this);});
                                                                    $('#firstname').val(data.customer[0]['Firstname']);
                                                                    $('#surname').val(data.customer[0]['Surname']);
                                                                    $('#email').val(data.customer[0]['Email']);
                                                                    $('#phone').val(data.customer[0]['Phone']);
                                                                    $('#address_1').val(data.customer[0]['Address_1']);
                                                                    $('#address_2').val(data.customer[0]['Address_2']);
                                                                    $('#address_3').val(data.customer[0]['Address_3']);               
                                                                    $('#town').val(data.customer[0]['Town']);
                                                                    $('#postcode').val(data.customer[0]['Postcode']);                                                    
                                                                    $('#country').val(data.customer[0]['Country']);                                                    
                                                                    $('input[type="text"]').change(function(e){updateForm(this);});
                                                                    $('#country').change(function(e){updateForm(this);});
                                                                    $('#discountcode').change(function(e){updateForm(this);});
                                                                    if($('#terms').length)
                                                                     $('#terms').html(data.terms[0]['Value']);
                                                                    if($('#postaladdr').length)
                                                                     $('#postaladdr').html('<p>' + data.customer[0]['Title'] + ' ' + data.customer[0]['Firstname'] + ' ' + data.customer[0]['Surname'] + '</p><p>'
                                                                                           + data.customer[0]['Address_1'] + '<br/>' + data.customer[0]['Address_2'] + '<br/>' + data.customer[0]['Address_3']
                                                                                           + data.customer[0]['Town'] + '<br/>' + data.customer[0]['Postcode'] + '<br/>' + data.customer[0]['Country'] + '</p><p>'
                                                                                           + data.customer[0]['Phone'] + '</p><p>' + data.customer[0]['Email'] + '</p>' );
                                                                    $('#editorder').click(function(){location.href = 'order.html';});
                                                                         }, 'json');
}



var ppages = ['confirmation.html'];
if(ppages.indexOf(location.href.split('/').pop()) !== -1)
{
  basketID = localStorage.getItem('OrderID');
  var template = $('#pageul').find('li:first').clone(true);
  var discnt   = $('#pageul').find('li:nth-child(2)').clone(true);
  var postg    = $('#pageul').find('li:nth-child(3)').clone(true);
  var grand    = $('#pageul').find('li:nth-child(4)').clone(true);
  template.unwrap();
  $('#page').find('li:first').remove();
  $('#page').find('li:first').remove();
  $('#page').find('li:first').remove();
  $('#page').find('li:first').remove();
  $('#pageul').remove();

  
  $.post("assets/php/process.php",JSON.stringify({
                                                   mode: "get_order",
                                                   basketID: basketID,
                                                 }),function(data,status){
                                                         $.each(data.basket,function(i, element){
                                                                    $('#page').append(template.html().replace(/::UID::/g
                                                                    ,element.ItemID)
                                                                           .replace('::Name::',element.Name)
                                                                           .replace('::Qty::',element.Quantity)
                                                                           .replace('::Prc::',element.Price)
                                                                           .replace('::Tot::',element.Total));
                                                                                                });
                                                                    $('#page').append(discnt.html().replace('::dct::',data.grand[0]['Discount']));
                                                                    $('#page').append(postg.html().replace('::pst::',data.grand[0]['Postage']));
                                                                    $('#page').append(grand.html().replace('::Grd::',data.grand[0]['Grand']));
                                                                    
                                                                    if(data.grand[0]['Discount'] == 0)
                                                                    {
                                                                      $("#dog").addClass("hide");
                                                                    }
                                                                    else
                                                                      $("#dog").removeClass("hide");
                                                                                                                                      
                                                                    
                                                                    
                                                                    if($('#postaladdr').length)
                                                                     $('#postaladdr').html('<p>' + data.customer[0]['Title'] + ' ' + data.customer[0]['Firstname'] + ' ' + data.customer[0]['Surname'] + '</p><p>'
                                                                                           + data.customer[0]['Address_1'] + '<br/>' + data.customer[0]['Address_2'] + '<br/>' + data.customer[0]['Address_3']
                                                                                           + data.customer[0]['Town'] + '<br/>' + data.customer[0]['Postcode'] + '<br/>' + data.customer[0]['Country'] + '</p><p>'
                                                                                           + data.customer[0]['Phone'] + '</p><p>' + data.customer[0]['Email'] + '</p>' );
                                                                                           
                                                                    if($('#paymentoutcome').length)
                                                                      $('#paymentoutcome').html('<p>Payment ID: ' + data.payment[0]['Payment_ID'] + '</p><p>Order Total: &pound;'
                                                                                           + data.payment[0]['Order_Total'] + '</p><p>Payment Status: '
                                                                                           + data.payment[0]['Payment_Status'] + '</p><p>Payment Receipt:</p><p><a href="'
                                                                                           + data.payment[0]['Receipt_URL'] + '" target="blank">' + data.payment[0]['Receipt_URL'] + '</a></p>');
                                                                         }, 'json');
}


 
function updateForm(control)
{
  var id = control.id;
  var country = control.value;
  switch(id)
  {
   case 'country':
                       $.post("assets/php/process.php",JSON.stringify({mode: "postage_chg", country: country, basketID: basketID}),function(data, status){
                                                                                                                                      $("#ptot").html('&pound;' + data.grand[0]['Postage']);
                                                                                                                                      $("#gtot").html('&pound;' + data.grand[0]['Grand']);
                                                                                                                                    }, 'json'); 
                                                                                                                                     break;
   case 'discountcode':
                       $.post("assets/php/process.php",JSON.stringify({mode: "discount_chg", discount: country, basketID: basketID}),function(data, status){
                                                                                                                                      $("#ptot").html('&pound;' + data.grand[0]['Postage']);
                                                                                                                                      if(data.grand[0]['Discount'] == 0)
                                                                                                                                      {
                                                                                                                                        $("#dog").addClass("hide");
                                                                                                                                      }
                                                                                                                                      else
                                                                                                                                        $("#dog").removeClass("hide");
                                                                                                                                      $("#dcnt").html('-&pound;' + data.grand[0]['Discount']);
                                                                                                                                      $("#gtot").html('&pound;' + data.grand[0]['Grand']);
                                                                                                                                    }, 'json'); 
                                                                                                                                    break;
   default:
       //alert('ID is: ' + id);
                       $.post("assets/php/process.php",JSON.stringify({mode: "form_update", field: id, value: country, basketID: basketID}),function(data, status){
                                                                                                                                    }, 'json'); 
                                                                                                                                    break;

       break;
  }
} 
 
 ppages = ['flowers.html','landscapes.html','portraits.html','buildings.html','figures.html'];
 if(ppages.indexOf(location.href.split('/').pop()) !== -1)
 {
   var template = $('#pageul').find('li:first').clone(true);
   template.unwrap();
   $('#page').find('li:first').remove();
   $('#pageul').remove();
   
   var insert = '<button class="close-button" data-close aria-label="Close reveal" type="button"><span aria-hidden="true">&times;</span></button>';
   var alertHTMLSuccess = '<div class="callout success" data-closable="fade-out"><p>Picture added to basket.</p><button class="close-button" aria-label="Dismiss alert" type="button" data-close><span aria-hidden="true">&times;</span></button></div>';   
   var alertHTMLLimit = '<div class="callout alert" data-closable="fade-out"><p>Insufficient remaining prints.</p><button class="close-button" aria-label="Dismiss alert" type="button" data-close><span aria-hidden="true">&times;</span></button></div>';   

   $.post("assets/php/process.php",JSON.stringify({
                                                    mode: "get_pictures",
                                                    page: /[^/]*$/.exec(window.location.pathname)[0]
                                                  }),function(data,status){
                                                         $.each(data.pictures,function(i, element){
                                                                     if (i % 2 === 0)
                                                                       $('#page').append(template.html().replace('::title::',element.Name)
                                                                           .replace('::description::','<p>' + element.Description + '</p><br/><p>Picture size including border: ' + element.Height_cm + 'cm &times ' + element.Width_cm + 'cm (' + element.Height_in + '" &times ' + element.Width_in 
                                                                                                            + '")</p><p>Limited edition of ' + element.Quantity + '</p>')
                                                                           .replace('::img::',element.LowRes)
                                                                           .replace('::jmg::',element.HighRes)
                                                                           .replace(/::inx::/g,element.UID)
                                                                           .replace('::price::',element.Price)
                                                                           .replace('::offset::','medium-offset-0'));
                                                                     else
                                                                       $('#page').append(template.html().replace('::title::',element.Name)
                                                                           .replace('::description::','<p>' + element.Description + '</p><br/><p>Picture size including border: ' + element.Height_cm + 'cm &times ' + element.Width_cm + 'cm (' + element.Height_in + '" &times ' + element.Width_in 
                                                                                                            + '")</p><p>Limited edition of ' + element.Quantity + '</p>')
                                                                           .replace('::img::',element.LowRes)
                                                                           .replace('::jmg::',element.HighRes)
                                                                           .replace(/::inx::/g,element.UID)
                                                                           .replace('::price::',element.Price)
                                                                           .replace('::offset::','large-offset-1 medium-offset-1'));                                     
                                     
                                                         $("#image-" + element.UID).click(function(){
                                                                                                      $modal.html('<img src="assets/img/' + element.HighRes + '">' + insert).foundation('open');
                                                                                                    });

                                                         $("#basket-" + element.UID).click(function(){
                                                                                                      $.post("assets/php/process.php",JSON.stringify({
                                                                                                                                                       mode: "basket_Add",
                                                                                                                                                       item: element.UID,
                                                                                                                                                       basketID: basketID
                                                                                                                                                     }),function(data,status){
                                                                                                                                                                               if(typeof data.result[0]['limit'] === "undefined" && data.result[0]['added'] == 'added')
                                                                                                                                                                               {
                                                                                                                                                                                 $("#alert-" + element.UID).html(alertHTMLSuccess);
                                                                                                                                                                               }
                                                                                                                                                                               else
                                                                                                                                                                               {
                                                                                                                                                                               $("#alert-" + element.UID).html(alertHTMLLimit);
                                                                                                                                                                               }
                                                                                                                                                                               $("#alert-" + element.UID).addClass('large-5');
                                                                                                                                                                               $(document).foundation();
                                                                                                                                                                               setTimeout(function(){$('#alert-' + element.UID + ' div button').trigger('click');}, 5000);
                                                                                                                                                                               setTimeout(function(){$('#alert-' + element.UID).removeClass('large-5');}, 6000);
                                                                                                                                                                             }, 'json');
                                                                                                    });



                                                                                                  });
                                                                          }, 'json');

  var $modal = $('#animatedModal10');
}


